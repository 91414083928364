@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;700&display=swap");

body {
  color: rgb(8, 26, 74);
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(249, 250, 254);
  font-size: 16px;
}

h3 {
  font-family: "Epilogue", sans-serif;
}

a {
  text-decoration: none;
  color: rgb(0, 105, 255);
  transition: color 0.25s ease-in;
}

a:hover {
  color: rgb(67, 144, 252);
  text-decoration: none;
}

footer,
footer .developed_by {
  font-family: "Epilogue", sans-serif;
  color: rgba(8, 27, 75, 0.25) !important;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  margin: 1rem 0 0.3rem;
}

footer p,
footer .developed_by p {
  margin: 0;
  color: rgba(8, 27, 75, 0.45) !important;
}

footer .developed_by {
  gap: 0.5rem;
  flex-direction: row;
  margin: 0.3rem 0;
}

::selection {
  background: rgba(0, 105, 255, 0.2); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba(0, 105, 255, 0.2); /* Gecko Browsers */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  padding: 0 20px;
}

@media (min-width: 500px) {
  main {
    padding: 10px 40px;
  }
}

/* @media (min-width: 769px) {
  footer,
  footer .developed_by {
    flex-direction: row;
    gap: 3rem;
  }

  footer .developed_by {
    gap: 0.5rem;
  }
} */

@media (min-width: 1024px) {
  main {
    padding: 10px 120px;
  }
}

@media (min-width: 1366px) {
  main {
    padding: 20px 240px;
  }
}

@media (min-width: 1920px) {
  main {
    padding: 40px 300px;
  }
}
