.Toolbar {
  height: 55px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: rgb(252, 253, 254);
  border-bottom: rgb(0, 105, 255) 1px solid;
  box-shadow: rgb(11 43 158 / 5%) 0px 6px 20px -6px;
  z-index: 10;
}

.Toolbar nav {
  height: 100%;
}

@media (min-width: 500px) {
  .Toolbar {
    padding: 10px 40px 5px;
  }
}

@media (max-width: 768px) {
  nav {
    display: none;
  }
}

@media (min-width: 1024px) {
  .Toolbar {
    padding: 10px 120px;
  }
}
@media (min-width: 1366px) {
  .Toolbar {
    padding: 20px 240px;
  }
}

@media (min-width: 1920px) {
  .Toolbar {
    padding: 40px 300px;
  }
}
