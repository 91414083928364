.NavigationItem {
  padding: 10px 0 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: auto;
  font-weight: 500;

  font-family: "Epilogue", sans-serif;
}

.NavigationItem a {
  color: rgb(8, 27, 75);
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  transition: color 0.25s ease-in;
}

.NavigationItem a:active,
.NavigationItem a.active {
  font-weight: 500;
}

.NavigationItem a:hover {
  border-radius: 10px;
  color: rgb(72, 91, 139);
}

@media (max-width: 768px) {
  .NavigationItem {
    justify-content: flex-start;
    margin: 10px 0;
    width: 100%;
  }
}
