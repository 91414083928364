.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .NavigationItems {
    flex-flow: column;
  }
}