.Btn {
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
}

.Btn button {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  color: rgb(8, 27, 75);
  cursor: pointer;
  transition: color 0.25s ease-in;
}

.Btn button:hover {
  color: rgb(72, 91, 139);
}
