.SubWeatherReport {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
}

.SubWeatherReport p {
  margin: 3px 0;
}

.gray {
  color: rgb(77, 91, 124);
}

.SubWeatherReport svg {
  margin-bottom: -8px;
}
