.SideMenu {
  position: fixed;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 40px 20px;
  box-sizing: border-box;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: rgb(252, 253, 254);
  font-size: 1.2rem;
  border-radius: 0 0 12px 12px;
}

.Logo {
  margin-bottom: 23px;
}

@media (min-width: 500px) {
  .SideMenu {
    padding: 40px 40px;
  }
}

@media (min-width: 769px) {
  .SideMenu {
    display: none;
  }
}

.Open {
  transform: translateY(0);
}

.Close {
  transform: translateY(-100%);
}
