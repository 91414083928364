.Menubtn {
  display: none;
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 300;
  color: rgb(8, 27, 75);
}

@media (max-width: 768px) {
  .Menubtn {
    display: block;
  }
}
