.WeatherForecast .searchBox {
  margin: 25px 0 0;
}

.gray {
  color: rgb(77, 91, 124);
  margin: 0.2rem 0;
}

h3 {
  font-size: 1.1rem;
  margin: 40px 0 10px !important;
}

.loading {
  font-family: "Epilogue", sans-serif;
  width: 100%;
  height: calc(100vh - 92.79px - 37.79px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading animation */

@keyframes ldioJz9hdxy7m7 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldioJz9hdxy7m7 div {
  box-sizing: border-box !important;
}
.ldioJz9hdxy7m7 > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #081a4a transparent #081a4a transparent;
  animation: ldioJz9hdxy7m7 1s linear infinite;
}
.ldioJz9hdxy7m7 > div:nth-child(2) {
  border-color: transparent;
}
.ldioJz9hdxy7m7 > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldioJz9hdxy7m7 > div:nth-child(2) div:before,
.ldioJz9hdxy7m7 > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #081a4a;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #081a4a;
}
.ldioJz9hdxy7m7 > div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #081a4a;
}
.loadingioSpinnerDualRingPwgiifgn0cc {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldioJz9hdxy7m7 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldioJz9hdxy7m7 div {
  box-sizing: content-box;
}
