.WeatherReport {
  margin: 20px 0;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: auto;
  row-gap: 20px;
}
