.AboutPage h3 {
  font-size: 1.5rem;
  margin: 2rem 0;
}

.AboutPage h4 {
  font-size: 1.1rem;
  margin-top: 2rem;
}

.AboutPage p {
  text-align: justify;
  line-height: 1.5rem;
}

.AboutPage ul {
  list-style-type: circle;
  padding-inline-start: 2rem;
  margin-bottom: 1.5rem;
}
