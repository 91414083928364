.TwentyFourHTable {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
  overflow: hidden;
}

.TwentyFourHTableIcons {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-around;
}

.TwentyFourHTableIcons > * {
  margin: 20px 10px;
}

.TwentyFourHTable .table {
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.TwentyFourHTable table {
  border-spacing: 0;
  table-layout: fixed;
  width: 95px;
  background-image: linear-gradient(
    100deg,
    rgba(200, 223, 255, 0.15),
    rgba(200, 223, 255, 0.5)
  );
}

.TwentyFourHTable img {
  width: 60px;
}

.TwentyFourHTable table td {
  box-sizing: border-box;
  text-align: center;
  height: 75px;
  width: 95px;
  font-weight: 500;
}

.TwentyFourHTable tr:first-child td {
  font-weight: 700;
  font-size: 0.9rem;
}

.TwentyFourHTable table td:nth-child(even) {
  background-color: white;
}

.TwentyFourHTable h3 {
  margin: 0.5rem;
}
