.Input {
  width: 100%;
  min-width: 150px;
  height: 35px;
  display: flex;
  border: 1px solid rgb(228 232 246);
  border-radius: 8px;
  background-color: #ffffff;
  margin: 15px 0 10px;
}

.Input input {
  width: 100%;
  color: #071108;
  box-sizing: border-box;
  background: none;
  padding: 5px 15px 5px 8px;
  font-size: 0.85rem;
  border: none;
}

.Input .search {
  margin: 40px 0 10px;
}

.Input input::placeholder {
  font-size: 0.9rem;
  color: rgb(77, 91, 124);
}

.Input:focus-within {
  box-shadow: 0 0 5px rgb(188, 199, 239);
}

.Input input:focus {
  outline: none;
}
