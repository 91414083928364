.MainWeatherReport {
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  margin: 20px 0 30px;
  font-family: "Epilogue", sans-serif;
}

.black {
  color: rgb(8, 27, 75);
}

.locationBox {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.location {
  font-weight: 600;
  margin: 0;
  color: rgba(77, 91, 124, 0.85);
}

.locationBox svg {
  width: 20px;
  color: rgba(77, 91, 124, 0.85);
}

.weatherBox {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-around;
  margin: 10px 0;
}

.mainTemp {
  font-size: 5rem;
  font-weight: 600;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.line {
  border: 1px solid rgba(77, 91, 124, 0.25);
  margin: 10px 0;
}

.catchIndex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: rgb(141, 141, 141);
  font-size: 1.2rem;
  font-weight: 600;
}

.catchIndex p {
  margin: 0;
}

.weather {
  margin: 0;
  color: rgb(141, 141, 141);
  font-weight: 500;
}
