.Logo {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(8, 27, 75);
  min-width: 115px;
  font-family: "Epilogue", sans-serif;
  transition: color 0.25s ease-in;
}

.Logo:hover {
  color: rgb(72, 91, 139);
}
