.FlexBreakRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 35px;
}

.FlexBreakColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 768px) {
  .FlexBreakRow {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
  }
}