.WeeklyTable {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
  overflow: hidden;
}

.WeeklyTableIcons {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-around;
}

.WeeklyTableIcons > * {
  margin: 20px 10px;
}

.WeeklyTable .table {
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.WeeklyTable table {
  border-spacing: 0;
  table-layout: fixed;
  width: 95px;
  background-image: linear-gradient(
    100deg,
    rgba(0, 69, 91, 0.05),
    rgba(0, 69, 91, 0.075)
  );
}

.WeeklyTable img {
  width: 60px;
}

.WeeklyTable table td {
  box-sizing: border-box;
  text-align: center;
  height: 75px;
  width: 95px;
  font-weight: 500;
}

.WeeklyTable tr:first-child td {
  font-weight: 700;
  font-family: "Epilogue", sans-serif;
  font-size: 0.9rem;
}

.WeeklyTable table td:nth-child(even) {
  background-color: white;
}
